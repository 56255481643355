export const TABLE_HEADERS = [
  {
    text: '幼稚園名',
    value: 'name',
    sortable: false,
  },
  {
    text: '市区町村',
    value: 'city',
    sortable: false,
  },
  {
    text: '種別',
    value: 'type',
    sortable: false,
  },
  {
    text: '給食',
    value: 'lunchChipText',
    sortable: false,
  },
  {
    text: '園バス',
    value: 'busChipText',
    sortable: false,
  },
  {
    text: '預かり保育',
    value: 'childcareChipText',
    sortable: false,
  },
  {
    text: 'プレ保育',
    value: 'preChipText',
    sortable: false,
  },
  {
    text: '制服',
    value: 'uniformChipText',
    sortable: false,
  },
]

// 公立/私立/国立
export const TYPES_MASTER = ['公立', '私立', '国立']

// 有り/無し/不明
export const YES_NO_MASTER = ['有り', '無し', '不明']

// 全カラム
export const ALL_COLUMNS = [
  { id: 'name', name: '幼稚園名' },
  { id: 'city', name: '市区町村' },
  { id: 'type', name: '種別' },
  { id: 'lunch', name: '給食' },
  { id: 'bus', name: '園バス' },
  { id: 'childcare', name: '預かり保育' },
  { id: 'pre', name: 'プレ保育' },
  { id: 'uniform', name: '制服' },
]
// 表示列を選択できるカラム
export const TABLE_DISP_SELECT_COLUMNS = ALL_COLUMNS.filter(
  (c) => c.id !== 'name'
)
// 絞り込みできるカラム
export const FILTER_COLUMNS = ALL_COLUMNS.filter((c) => c.id !== 'name')

export const CITIES_MASTER = [
  '千代田区',
  '中央区',
  '港区',
  '新宿区',
  '文京区',
  '台東区',
  '墨田区',
  '江東区',
  '品川区',
  '目黒区',
  '大田区',
  '世田谷区',
  '渋谷区',
  '中野区',
  '杉並区',
  '豊島区',
  '北区',
  '荒川区',
  '板橋区',
  '練馬区',
  '足立区',
  '葛飾区',
  '江戸川区',
  '八王子市',
  '立川市',
  '武蔵野市',
  '三鷹市',
  '青梅市',
  '府中市',
  '昭島市',
  '調布市',
  '町田市',
  '小金井市',
  '小平市',
  '日野市',
  '東村山市',
  '国分寺市',
  '国立市',
  '福生市',
  '狛江市',
  '東大和市',
  '清瀬市',
  '東久留米市',
  '武蔵村山市',
  '多摩市',
  '稲城市',
  '羽村市',
  'あきる野市',
  '西東京市',
  '瑞穂町',
  '日の出町',
  '檜原村',
  '奥多摩町',
  '大島町',
  '利島村',
  '新島村',
  '神津島村',
  '三宅村',
  '御蔵島村',
  '八丈町',
  '青ヶ島村',
  '小笠原村',
]
