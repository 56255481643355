























































































import { defineComponent, reactive, SetupContext } from '@vue/composition-api'
import originData from '../assets/data.json'

export default defineComponent({
  name: 'Detail',
  setup(_, context: SetupContext) {
    const { detailState, showDetailDialog } = useDetail()
    const route = context.root.$route
    const name = route.params.name
    const item = originData.filter((d) => d.name === name)[0]
    showDetailDialog(item)
    return { detailState }
  },
})

function useDetail() {
  const detailState = reactive({
    item: {} as { [key: string]: string },
    commonItems: {
      type: { type: 'type', icon: 'mdi-school', text: '' },
      address: { type: 'address', icon: 'mdi-map-marker', text: '' },
      tel: { type: 'tel', icon: 'mdi-phone', text: '' },
      uniform: { type: 'uniform', icon: 'mdi-tshirt-crew', text: '' },
    } as {
      [key: string]: { [key: string]: string }
    },
    splitItem: {
      lunch: { name: '給食', text: [] },
      bus: { name: '園バス', text: [] },
      childcare: { name: '預かり保育', text: [] },
      pre: { name: 'プレ保育', text: [] },
      memo: { name: 'メモ', text: [] },
    } as { [key: string]: { [key: string]: any } },
    mapSource: '',
  })
  function showDetailDialog(item: { [key: string]: string }) {
    detailState.item = item
    createMapSource(item)
    setCommonItemTexts(item)
    splitText(item)
  }
  function splitText(item: { [key: string]: string }): void {
    detailState.splitItem.lunch.text = item.lunch.split('\n')
    detailState.splitItem.bus.text = item.bus.split('\n')
    detailState.splitItem.childcare.text = item.childcare.split('\n')
    detailState.splitItem.pre.text = item.pre.split('\n')
    detailState.splitItem.memo.text = item.memo.split('\n')
  }
  function setCommonItemTexts(item: { [key: string]: string }) {
    detailState.commonItems.type.text = item.type
    detailState.commonItems.address.text = createFullAddress(
      item.city,
      item.address
    )
    detailState.commonItems.tel.text = item.tel
    detailState.commonItems.uniform.text = item.uniform
  }
  function createMapSource(item: { [key: string]: string }) {
    detailState.mapSource =
      'https://maps.google.co.jp/maps?q=東京都' +
      createFullAddress(item.city, item.address) +
      ' ' +
      item.name +
      '&output=embed&t=m&z=16&hl=ja'
  }
  function createFullAddress(city: string, address: string) {
    return city + address
  }
  return { detailState, showDetailDialog }
}
