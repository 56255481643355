






































































































































































































































import {
  defineComponent,
  reactive,
  SetupContext,
  watch,
} from '@vue/composition-api'
import {
  CITIES_MASTER,
  TABLE_HEADERS,
  TYPES_MASTER,
  YES_NO_MASTER,
} from '@/const/const'
import originData from '../assets/data.json'
import MouseOverChip from '../components/MouseOverChip.vue'

export default defineComponent({
  name: 'List',
  components: {
    MouseOverChip,
  },
  setup(_, context: SetupContext) {
    const { initData, CITIES } = useInit()
    const { dataState } = useData(initData, CITIES)

    function getChipColor(text: string): string {
      switch (text) {
        case '有り':
          return 'primary'
        case '無し':
          return 'error'
        default:
          return 'grey'
      }
    }

    return {
      initData,
      dataState,
      getChipColor,
      TABLE_HEADERS,
      YES_NO_MASTER,
      ...useFilterForm(dataState, CITIES),
      ...useDetail(),
      ...useTableFooter(),
      ...useScrollTop(context),
    }
  },
})
// -------------------- useInit --------------------
function useInit() {
  const initData = addChipData()
  // データが存在する市区町村
  const existCities = uniq(initData.map((d: any) => d.city))
  // 市区町村マスタからデータが存在するものだけに絞る（順番保持のため）
  const CITIES = CITIES_MASTER.filter((c) => existCities.includes(c))

  function addChipData() {
    return originData.map((obj) => {
      return {
        ...obj,
        lunchChipText: createChipText(obj.lunch),
        busChipText: createChipText(obj.bus),
        childcareChipText: createChipText(obj.childcare),
        preChipText: createChipText(obj.pre),
        uniformChipText: createChipText(obj.uniform),
      }
    })
  }
  function uniq(array: string[]) {
    return array.filter(
      (elem: any, index: number, self: any) => self.indexOf(elem) === index
    )
  }
  function createChipText(text: string) {
    switch (text) {
      case '':
        return '不明'
      case '無し':
      case '無':
      case 'なし':
        return '無し'
      default:
        return '有り'
    }
  }
  return { initData, CITIES }
}
// -------------------- useData --------------------
function useData(initData: { [key: string]: any }, CITIES: string[]) {
  const dataState = reactive({
    search: '',
    filterdData: initData,
    filterValues: {
      city: CITIES,
      type: TYPES_MASTER,
      lunch: YES_NO_MASTER,
      bus: YES_NO_MASTER,
      childcare: YES_NO_MASTER,
      pre: YES_NO_MASTER,
      uniform: YES_NO_MASTER,
    },
  })
  watch(
    () => dataState.filterValues,
    () => filter(),
    { deep: true }
  )
  function filter(): void {
    dataState.filterdData = initData
      .filter((data: any) => {
        for (const city of dataState.filterValues.city) {
          if (data.city === city) return true
        }
      })
      .filter((data: any) => {
        for (const type of dataState.filterValues.type) {
          if (data.type === type) return true
        }
      })
      .filter((data: any) => {
        for (const lunch of dataState.filterValues.lunch) {
          if (data.lunchChipText === lunch) return true
        }
      })
      .filter((data: any) => {
        for (const bus of dataState.filterValues.bus) {
          if (data.busChipText === bus) return true
        }
      })
      .filter((data: any) => {
        for (const childcare of dataState.filterValues.childcare) {
          if (data.childcareChipText === childcare) return true
        }
      })
      .filter((data: any) => {
        for (const pre of dataState.filterValues.pre) {
          if (data.preChipText === pre) return true
        }
      })
      .filter((data: any) => {
        for (const pre of dataState.filterValues.uniform) {
          if (data.uniformChipText === pre) return true
        }
      })
  }
  return { dataState }
}

// -------------------- useFilterForm --------------------
function useFilterForm(dataState: { [key: string]: any }, CITIES: string[]) {
  const filterFormState = reactive({
    items: [
      { id: 'city', name: '市区町村', vals: CITIES },
      { id: 'type', name: '種別', vals: TYPES_MASTER },
      { id: 'lunch', name: '給食', vals: YES_NO_MASTER },
      { id: 'bus', name: '園バス', vals: YES_NO_MASTER },
      { id: 'childcare', name: '預かり保育', vals: YES_NO_MASTER },
      { id: 'pre', name: 'プレ保育', vals: YES_NO_MASTER },
      { id: 'uniform', name: '制服', vals: YES_NO_MASTER },
    ],
    citiesCheckAll: true,
    isCitiesIndeterminate: false,
  })
  function handleCheckAllCities(val: boolean): void {
    dataState.filterValues.city = val ? CITIES : []
  }
  function handleCheckedCities(arr: string[]) {
    const checkedCount = arr.length
    // 全てにチェックされていたら全チェックにチェックつける
    filterFormState.citiesCheckAll = checkedCount === CITIES.length
    // 中途半端にチェックされていたら全チェックをIndeterminateに
    filterFormState.isCitiesIndeterminate =
      0 < checkedCount && checkedCount < CITIES.length
  }

  return {
    filterFormState,
    handleCheckAllCities,
    handleCheckedCities,
  }
}
// -------------------- useDetail --------------------
function useDetail() {
  const detailState = reactive({
    isShowDetailDialog: false,
    item: {} as { [key: string]: string },
    commonItems: {
      type: { type: 'type', icon: 'mdi-school', text: '' },
      address: { type: 'address', icon: 'mdi-map-marker', text: '' },
      tel: { type: 'tel', icon: 'mdi-phone', text: '' },
      uniform: { type: 'uniform', icon: 'mdi-tshirt-crew', text: '' },
    } as {
      [key: string]: { [key: string]: string }
    },
    splitItem: {
      lunch: { name: '給食', text: [] },
      bus: { name: '園バス', text: [] },
      childcare: { name: '預かり保育', text: [] },
      pre: { name: 'プレ保育', text: [] },
      memo: { name: 'メモ', text: [] },
    } as { [key: string]: { [key: string]: any } },
    mapSource: '',
  })
  function showDetailDialog(item: { [key: string]: string }) {
    detailState.item = item
    createMapSource(item)
    setCommonItemTexts(item)
    splitText(item)
    detailState.isShowDetailDialog = true
  }
  function splitText(item: { [key: string]: string }): void {
    detailState.splitItem.lunch.text = item.lunch.split('\n')
    detailState.splitItem.bus.text = item.bus.split('\n')
    detailState.splitItem.childcare.text = item.childcare.split('\n')
    detailState.splitItem.pre.text = item.pre.split('\n')
    detailState.splitItem.memo.text = item.memo.split('\n')
  }
  function setCommonItemTexts(item: { [key: string]: string }) {
    detailState.commonItems.type.text = item.type
    detailState.commonItems.address.text = createFullAddress(
      item.city,
      item.address
    )
    detailState.commonItems.tel.text = item.tel
    // 制服は不明の場合不明と出したいので uniformChipText を使う
    detailState.commonItems.uniform.text = item.uniformChipText
  }
  function createMapSource(item: { [key: string]: string }) {
    detailState.mapSource =
      'https://maps.google.co.jp/maps?q=東京都' +
      createFullAddress(item.city, item.address) +
      ' ' +
      item.name +
      '&output=embed&t=m&z=16&hl=ja'
  }
  function createFullAddress(city: string, address: string) {
    return city + address
  }
  return { detailState, showDetailDialog }
}
// -------------------- useTableFooter --------------------
function useTableFooter() {
  const footerProps = {
    'items-per-page-text': '1ページに表示する行数:',
    'items-per-page-options': [5, 7, 10],
  }
  return { footerProps }
}
// -------------------- useScrollTop --------------------
function useScrollTop(context: SetupContext) {
  const scrollTopState = reactive({
    isShow: false,
  })
  function onScroll(e: any) {
    if (typeof window === 'undefined') return
    const top = window.pageYOffset || e.target.scrollTop || 0
    scrollTopState.isShow = top > 500
  }
  function toTop() {
    context.root.$vuetify.goTo(0)
  }
  return { scrollTopState, onScroll, toTop }
}
