















































import { defineComponent, reactive } from '@vue/composition-api'
import List from './views/List.vue'

export default defineComponent({
  name: 'App',
  components: {
    List,
  },
  setup() {
    const state = reactive({
      isShowDrawer: false,
      listGroup: '',
    })
    const drawerItems = [
      {
        title: 'ホーム',
        icon: 'mdi-home',
        to: '/',
      },
      {
        title: 'フィードバック',
        icon: 'mdi-message-reply-text',
        to: '/feedback',
      },
      {
        title: 'プライバシーポリシー',
        icon: 'mdi-file-document-outline',
        to: '/privacy-policy',
      },
    ]
    return { List, state, drawerItems }
  },
})
