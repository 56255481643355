














import { defineComponent } from '@vue/composition-api'
type Props = {
  chipText: string // チップに表示するテキスト
  toolchipText: string // マウスオンしたときツールチップに表示するテキスト
}
export default defineComponent({
  name: 'MouseOverChip',
  props: {
    chipText: String,
    toolchipText: String,
  },
  setup(props: Props) {
    function getChipColor(text: string): string {
      switch (text) {
        case '有り':
          return 'primary'
        case '無し':
          return 'error'
        default:
          return 'grey'
      }
    }

    function isYes(text: string): boolean {
      return text === '有り'
    }

    return { props, getChipColor, isYes }
  },
})
